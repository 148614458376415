import WebServiceRequest from '../Api/WebServiceRequest'

class GetSiteSettings extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('SiteSettings')
  }
}

class SetSiteSettings extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('SiteSettings')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

export {
  GetSiteSettings,
  SetSiteSettings
}
